import extend from 'extend';
import config from './config';

const LAN = {
    APPName: ['International Press of Boston', 'International Press of Boston'],
    welcome: ['欢迎来到 International Press of Boston', 'Welcome to International Press of Boston'],
    welcomeBR: ['欢迎来到<br>International Press of Boston', 'Welcome to<br>International Press of Boston'],

    languageStr: {
        0: ['中文', '中文'],
        1: ['Endlish', 'Endlish']
    },

    journal: {
        home: ['期刊首页', 'Journals Home'],
        editors: ['编辑', 'Editors'],
        sEditors: ['系列编辑', 'Series Editors'],
        submissions: ['提交的资料', 'Submissions'],
        accepted: ['已接受', 'Accepted'],
        onlines: ['在线阅读', 'Read Online'],
        peryear: ['每年|US|期，', '|US| issues per year, '],
        peryear1: ['每年|US|期。', '|US| issues per year.'],

        since: ['自|YEAR|年以来，每年|US|期。', '|US| issues per year. Since |YEAR|.'],

        sinces: ['自|YEAR|年以来，', 'Since |YEAR|.'],
        sincep: ['每年|US|期。', '|US| issues per year. '],

        since1: ['自|YEAR|年以来，年鉴。', 'Annual Book. Since |YEAR|.'],
        print: ['ISSN 打印 |PRINT| ', 'ISSN Print |PRINT|'],
        online: ['ISSN 在线 |ONLINE|', 'ISSN Online |ONLINE|'],
        gotoCDM: ['转到|NAME|在线目录。', 'Go to |NAME| contents online.'],
        chief: ['主编', 'Editor-in-Chief'],
        editor: {
            name: ['姓名', 'Name'],
            affilliation: ['附属关系', 'Affiliation'],
            contact: ['联系方式', 'Contact'],
            webPage: ['网页', 'Web Page'],
            expertise: ['专业', 'Expertise'],
        },
        editorLabel: {
            'EDITOR-IN-CHIEF': ['主编', 'Editor-in-Chief'],
            'MGMT-B-C': ['Mgmt. Board Chairs', 'Mgmt. Board Chairs'],
            'MGMT-B-M': ['Management Board Members', 'Management Board Members'],
            'EXECUTIVE-EDITOR': ['Executive Editor', 'Executive Editor'],
            'CONSULTING-EDITOR': ['Consulting Editor', 'Consulting Editor'],
            'EABM': ['Editorial Advisory Board Member', 'Editorial Advisory Board Member'],
            'MANAGING-EDITOR': ['Managing Editor', 'Managing Editor'],
            'EDITORIAL-BOARD-MEMBER': ['Editorial Board Members', 'Editorial Board Members'],
            'HORNORARY-EDITOR': ['Honorary Editor', 'Honorary Editor'],
            'EDITOR': ['Editor', 'Editor'],
            'EDITOR-AT-LARGE': ['Editors-at-Large', 'Editors-at-Large'],
            'FOUNDING-EDITOR': ['Founding Editors', 'Founding Editors'],
            'TECHNICAL-EDITOR': ['Technical-Editor', 'Technical-Editor'],
            'ASSOCIATE-EDITOR': ['Associate Editors', 'Associate Editors'],
            'ADVISORY-BOARD-MEMBER': ['Advisory Board Members', 'Advisory Board Members'],
            'EDITORIAL-ASSISTANT': ['Editorial Assistant', 'Editorial Assistant'],
            'SCIENTIFIC-ADVISORY-BOARD-CHAIR': ['Scientific Advisory Board Chair', 'Scientific Advisory Board Chair'],
            'SCIENTIFIC-ADVISORY-BOARD-MEMBER': ['Scientific Advisory Board Member', 'Scientific Advisory Board Member'],
            'PUBLISHER-REPRESENTATIVE': ['Publisher‘s Representative', 'Publisher‘s Representative'],
            'JOURNAL-SECRETARY': ['Journal Secretary', 'Journal Secretary'],
        },
        scope: ['目标和范围', 'Aims and Scope'],
        intr: ['介绍', 'Introduction'],
        pub: ['出版', 'Publication'],
        pubs: ['发布自', 'Publishing since'],
        eachMonth: ['分别为', 'in'],
        indexView: ['索引/审阅', 'Indexing / Reviewing'],
        indexBy: ['索引依据', 'Indexed By'],
        reviewedBy: ['审核人', 'Reviewed By'],
        citation: ['引文报告指标', 'Citation Report Metrics'],
        sponsorship: ['赞助', 'Sponsorship'],
        specialNotices: ['注意', 'Special Notices'],
        general: ['一般信息', 'General Information'],
        about: ['关于提交', 'About Submissions'],
        selection: ['选择和验收', 'Selection and Acceptance'],
        acceptedTitle: ['正在等待发表的已接受论文', 'Accepted Papers Currently Awaiting Publication'],
        actIssue: ['针对问题', 'For Issue'],
        actSeq: ['序列', 'Seq.'],
        actTitle: ['标题', 'Title'],
        actAuthor: ['作者', 'Author(s)'],
        actDate: ['接受日期', 'Date of Acceptance'],
        newest: ['最新', 'NEWEST'],
        yearVolume: ['|Y|年|V|卷', 'Volume |V| (|Y|)'],
        yearVolume1: ['|Y|年', 'Volume |Y|'],
        volume: ['卷', 'Volume'],
        qq: ['第|Q|期', 'Number |Q|'],
        mailTo: ['邮件', 'E-Mail'],
        webLink: ['网站', 'Link'],
        totalI: ['共|T|篇', '|T| articles in total'],
        totalV: ['共|T|卷', '|T| articles in volume'],
        contents: ['目录', 'Contents'],
        pages: ['页数', 'pp.'],
        // thisVolumne: ['本卷', 'This Volume'],
        volumnes: ['卷', 'Volumes'],
        thisIssue: ['本期', 'This Issue'],
        allVolumes: ['所有卷', 'All Volumes'],
        thisVolume: ['本卷', 'This Volume'],
        journalHome: ['期刊首页', 'Home page'],
        VAI: ['卷和问题', 'Volumes and issues'],
        authors: ['作者', 'Authors'],
        author: ['作者', 'Author'],
        abstract: ['摘要', 'Abstract'],
        keyword: ['关键字', 'Keywords'],
        msc2010: ['2010年数学学科分类', '2010 Mathematics Subject Classification'],
        received: ['收到', 'Received'],
        receivedRevised: ['收到修订稿', 'Received revised'],
        accepted: ['接受', 'Accepted'],
        published: ['发布', 'Published'],
        remark: ['备注', 'Remark'],
        readOn: ['在|SITE|上阅读|NAME|', 'Read |NAME| on |SITE|'],
        noContents: ['内容尚未在线发布。', 'Content has not yet been published online.'],
        noVolumes: ['暂时没有任何卷', 'There are no volumes at the moment.'],
        closeIssues: ['待出版的已解决问题', 'Closed Issues Awaiting Publication'],
        closeIssueRight: ['目前，没有尚未出版的问题。', 'Currently, there are no closed issues awaiting publication.'],
        acceptedRight: ['目前，没有待发表的论文。', 'Currently, there are no papers to be published.'],
        noJournal: ['暂无相关期刊', 'No Journal']
    },

    month: {
        1: ['一月', 'January'],
        2: ['二月', 'February'],
        3: ['三月', 'March'],
        4: ['四月', 'April'],
        5: ['五月', 'May'],
        6: ['六月', 'June'],
        7: ['七月', 'July'],
        8: ['八月', 'August'],
        9: ['九月', 'September'],
        10: ['十月', 'October'],
        11: ['十一月', 'November'],
        12: ['十二月', 'December']
    },

    cookie: {
        notice: ['我们使用Cookie来改善您对我们网站的体验，点击“同意并关闭”即表示您同意在您的设备上存储并使用cookie，点击更多信息可以获得有关本网站上cookie使用的详细信息。', 'We use cookies to improve your experience with our website. By clicking "Agree and Close", you agree to store and use cookies on your device. Click "more information" to obtain detailed information about the use of cookies on this website.'],
        AC: ['同意并关闭', 'Agree and Close'],
        more: ['更多信息', 'More Information'],
        title: ['隐私政策', 'Privacy Policy']

    },
    intro: {
        i1: ['International Press of Boston, Inc. 是一家学术出版公司，成立于<span class="n-c-UI-blue">1992</span>年，总部位于马萨诸塞州萨默维尔(靠近哈佛大学)。', 'International Press of Boston, Inc. is a scholarly publishing company founded in <span class="n-c-UI-blue">1992</span>, and based in Somerville, Massachusetts (near to Harvard University).'],
        i2: ['我们目前出版了<span class="n-c-UI-blue">20种同行评审</span>的期刊和两本年度书籍，涉及纯数学和应用数学中当前研究的各个领域。', 'We currently publish <span class="n-c-UI-blue">20 peer-reviewed</span> journals and two annual books dealing with various fields of current research in pure and applied mathematics.'],
        i3: ['我们还出版高水平的数学和数学物理书籍，包括<span class="n-c-UI-blue">专著、教科书和几个长期运行</span>的图书系列。', 'We also publish high-level mathematics and mathematical physics book titles, including <span class="n-c-UI-blue">monographs, textbooks, and several long-running</span> book series.'],
        i4: ['International Press of Boston, Inc. 的编辑和作者来自一个全球性的人才网络，其中包括许多在各自领域最著名和最重要的数学家。', 'International Press draws its editors and authors from a worldwide network of talent which includes many of the most notable and important mathematicians in their respective fields.'],
    },

    unit: {
        f: ['份', ''],
        b: ['本', ''],
        p: ['页', 'pages'],
        b1: ['本', 'books'],
        x: ['系列', 'Series']
    },

    book: {
        belong: ['这本书是|SS|系列丛书的一部分', 'The volume is part of the |SS| book series'],
        noAuth: ['抱歉，您没有权限预览该书籍', 'Sorry, you do not have permission to preview this book'],
        forthcoming: ['即将发布', 'Forthcoming'],
        recently: ['最近发布', 'Recently Published'],
        featured: ['特色出版物', 'Featured Publications'],
        subscribed: ['已订阅', 'Subscribed'],
        MSC2020: ['MSC2020', 'MSC2020'],
        moreAuthor: ['点击查看更多|COUNT|作者', 'Click to view more |COUNT| authors'],
        pubDate: ['发布日期', 'Pub. Date'],
        medium: ['媒介', 'Medium'],
        binding: ['约束', 'Binding'],
        size: ['规格', 'Size, Etc.'],
        status: ['状态', 'Status'],
        price: ['价格', 'List Price'],
        noBook: ['暂无相关书籍', 'No Book'],
        printBookInfo: ['纸质书信息', 'Print Book Information'],
    },

    common: {
        more: ['更多', 'More'],
        pages: ['页数', 'Pages'],
        updateTime: ['此页面最后更新于', ' This page last updated'],
        updateLocalTime: ['本地时间', 'Local Time'],
        parentNotice: ['这一卷是单独出售的，也作为一套卷的一部分出售。', 'This volume is sold both independently and as part of a volume set.'],
        childNotice: ['这是一套包括以下卷的书，可以单独购买：', 'This is a set comprising the following volumes, which may be purchased independently:'],
        back: ['返回', 'Back'],
        catalogue: ['目录', 'Catalogue'],
        allJournal: ['所有期刊', 'All Journals Home Page'],
        allJournalOnline: ['在线期刊内容链接', 'Links to journal content online'],
        allOnline: ['所有在线期刊内容', 'All Journals Contents Online'],
        journalRing: ['期刊正在接收订阅', 'The journal is receiving subscriptions'],
        STY: ['Books by Shing-Tung Yau', 'Books by Shing-Tung Yau'],
        contactUs: ['联系我们', 'Contacts'],
        bookRing: ['书籍正在接收订阅', 'The book is receiving subscriptions'],
        byTitle: ['按标题', 'By Title'],
        byDate: ['日期', 'By Date'],
        byPrice: ['售价', 'Price'],
        total: ['总计', 'Total'],
        publishDate: ['发布于', 'Publish Date'],
        publishBy: ['发布人', 'Editors'],
        salePrice: ['售价', 'List Price'],
        salePrice1: ['售&nbsp;&nbsp;&nbsp;价', 'Price'],
        cBuy: ['点击购买', 'Click to Buy'],
        introduce: ['介绍', 'Description'],
        view: ['查看', 'View'],
        PDFAll: ['查看全文', 'Read full text'],
        PDFTable: ['目录预览', 'Table of contents'],
        PDFPreview: ['样章试读', 'Preview'],
        publications: ['出版信息', 'Publications'],
        publisher: ['出版商', 'Publisher'],
        noAuthBook: ['未授权阅读全文，请联系我们订阅（更多...）。', 'Unauthorized access to read full text. Please contact us to subscribe (More...).'],
        subscribe: ['订阅', 'Subscribe']
    },

    nav: {
        bookHome: ['书籍首页', 'Books Home'],
        home: ['主页', 'Home'],
        QQ: ['期刊', 'Journals'],
        QQHome: ['主页', 'Home Page'],
        QQOnline: ['在线期刊', 'Contents Online'],
        QQOnlineShort: ['在线期刊', 'Contents Online'],
        online: ['在线期刊内容', 'Journal Content Online'],
        book: ['书籍', 'Books'],
        bookSuccession: ['系列丛书', 'Book Series'],
        bookSuccessionList: ['系列丛书列表', 'Book Series List'],
        bookLight: ['教科书', 'Textbooks'],
        STY: ['Books by S.T. Yau', 'Books by S.T. Yau'],
        ordering: ['信息订购', 'Information & Ordering'],
        contactUs: ['联系我们', 'Contacts'],
        mine: ['个人中心', 'User Center'],
        login: ['登录', 'Login'],
        pLogin: ['个人登录', 'Personal Login'],
        oLogin: ['企业登录', 'Group Login'],
        logout: ['退出登录', 'Logout'],
        changeLogin: ['切换后，当前登录将失效，是否确定切换？', 'After switching, the current login will be invalidated. Are you sure to switch?']
    },

    mine: {
        recent: ['最新浏览', 'Latest browsing'],
        subscribe: ['我的订阅', 'My subscription'],
        message: ['我的消息', 'My news'],
        open: ['开放期刊', 'Open Access Journals'],
        noHis: ['你还没有任何浏览记录', 'You have no browsing record.'],
        noSub: ['您还没有任何订阅记录', 'You have no subscription record.']
    },

    home: {
        sold: ['2025期刊订阅现已出售', '2025 journal subscriptions now being sold'],
        soldDesc: ['我们目前正在销售2025年期刊订阅。', 'We are currently selling 2025 journal subscriptions.'],
        soldBtn: ['请访问<span class="n-c-UI-blue n-f-underline c-hover-target">我们的期刊订阅页面</span>下载2025年订阅价目表。', 'Please visit <span class="n-c-UI-blue n-f-underline c-hover-target">our Journal Subscriptions page</span> to download the 2025 subscription list price schedule.'],
        sTitle: ['国际出版社为您带来<span class="n-c-base">Acta Mathematica</span>和<span class="n-c-base">Arkiv fr mate matik', 'International Press brings you <span class="n-c-base">Acta Mathematica</span> and <span class="n-c-base">Arkiv för Matematik</span>'],
        sContent1: [
            "国际出版社是历史悠久的期刊Acta Mathematica和Arkiv fr mate matik的独家生产商和经销商，这是与它们的所有者和出版商皇家瑞典学院科学学院米塔·列夫勒研究所达成的协议。</br>",
            "International Press is the exclusive producer and distributor of the venerable journals Acta Mathematica and Arkiv för Matematik, by arrangement with their owner and publisher, the Institut Mittag-Leffler, of the Royal Swedish Academy of Sciences.</br>"
        ],
        sContent2: [
            "此外，通过与米塔·列夫勒研究所的安排，国际出版社提供Acta Mathematica和Arkiv fr mate matik从第一期(分别为1882年和1949年)到最近一期的全部内容的完全开放的在线访问。</br>",
            "Moreover, by arrangement with the Institut Mittag-Leffler, International Press provides <span class='n-c-base'>fully open online access</span> to the entire content of Acta Mathematica and Arkiv för Matematik — from their first issues (of 1882 and 1949 respectively) to the most recent.</br>"
        ],
        sContent3: [
            "关于Acta Mathematica和Arkiv fr Matematik的最新信息，请访问intlpress.com/acta和intlpress.com/arkiv.米塔·列夫勒研究所的网站，并查看我们网站上的期刊主页",
            "For current information about Acta Mathematica and Arkiv för Matematik, please visit the <a class='c-tab-link n-f-underline c-tab-link-event' href='http://www.mittag-leffler.se/about-us' target='_blank'>website of the Institut Mittag-Leffler</a>, and see the journals’ home pages on our website at <a class='c-tab-link n-f-underline c-tab-link-event' href='/acta' rel='nofollow'>link.intlpress.com/acta</a> and <a class='c-tab-link n-f-underline c-tab-link-event' href='/arkiv'>link.intlpress.com/arkiv</a>."
        ]
    },

    nui: {
        exit: ['再按一次返回键退出应用', 'Press the return key again to exit the application'],
        browser: ['您的浏览器版本过低，请使用Edge或其他高版本第三方浏览器访问', 'Your browser version is too low. Please use edge or other advanced third-party browser to visit'],
        loading: ['加载中，请稍后', 'Loading'],

        scroll: {
            pullUpNotice: ['上拉加载更多', 'Pull up loading more'],
            pullUpEndNotice: ['我也是有底线的~', 'No more']
        },

        dateSelect: {
            title: ['日期选择', 'Date Selector'],
            monthTitle: ['月份选择', 'Month Selector'],
            year: ['年', '-'],
            month: ['月', '-'],
            monthen: ['月', ''],
            day: ['日', ''],
            pleaseSelectRange: ['请选择完整的区间', 'Please select range'],
            single: ['单日', 'Single Day'],
            multiple: ['多日', 'Multiple Days'],
            weekTitles: {
                0: ['日', 'Sun'],
                1: ['一', 'Mon'],
                2: ['二', 'Tue'],
                3: ['三', 'Web'],
                4: ['四', 'Thu'],
                5: ['五', 'Fri'],
                6: ['六', 'Sat']
            }
        },

        page: {
            prev: ['上一页', 'Prev'],
            next: ['下一页', 'Next']
        },

        upload: {
            penddingNotice: ['上传中，请稍后', 'Uploding'],
            uploadFailed: ['上传失败', '上传失败'],
            fileError: ['文件类型/格式错误', 'File type or format error'],
            fileSizeTooSmall: ['您上传的文件内容过小，请重新上传', 'The file you uploaded is too small, please upload again'],
            fileSizeTooLarge: ['您上传的文件内容过大，最大可上传|SIZE|，请重新上传', 'The file you uploaded is too large, up to |SIZE|, please upload again'],
            all: ['所有', 'all'],
            warn: ['请选择|EXT|类型并且小于|SIZE|的文件，最多可上传|COUNT|个文件', 'Please upload |EXT| suffix and less than |SIZE| files, up to |COUNT|']
        },

        dateTypeSelect: {
            week: ['本周', 'Week'],
            month: ['本月', 'Month'],
            year: ['本年', 'Year'],
            more: ['更多', 'More']
        },

        selectAddress: {
            noLevelData: ['该类别下暂无数据', 'No data']
        },

        selectAddress: {
            noLevelData: ['该类别下暂无数据', 'No data'],
            province: ['省份', 'Province'],
            city: ['城市', 'City'],
            area: ['区/县', 'Area']
        },

        common: {
            timeout: ['正在努力加载中，请稍后~', 'Trying to load, please wait~'],
            timeoutError: ['请求超时，请调整网络重新尝试', 'Request timed out, please adjust the network to try again'],
            selected: ['已选择', 'Selected'],
            noSelected: ['您还未进行选择', 'No selected'],
            cancel: ['取消', 'Cancel'],
            ok: ['确定', 'OK'],
            pleaseEnterKeyword: ['请输入关键字', 'Please enter keyword'],
            pleaseEnter: ['请输入', 'Please enter'],
            pleaseSelect: ['请选择', 'Please select'],
            noData: ['还没有相关记录哦', 'No data'],
            emptyData: ['暂无相关记录', 'No data'],
            complete: ['完成', 'Complete'],
            save: ['保存', 'Save'],
            submit: ['提交', 'Submit'],
            add: ['新增', 'Add'],
            delete: ['删除', 'Delete'],
            edit: ['编辑', 'Edit'],
            yes: ['是', 'Yes'],
            no: ['否', 'No'],
            logout: ['退出登陆', 'Logout']
        }
    }
};

let LF = {
    exportLanguage: function () {
        var languageIndex = this.getLanguage(),
            languageCopy = extend(true, {}, LAN);

        return this.parseLanguage(languageCopy, languageIndex);
    },
    parseLanguageBySequence: function (lanSequence) {
        var languageIndex = this.getLanguage();

        lanSequence = lanSequence.split('|');
        var lanArr = LAN;
        for (var i = 0; i < lanSequence.length; i++) {
            lanArr = lanArr[lanSequence[i]];
        }
        return Array.isArray(lanArr) ? lanArr[languageIndex] : lanArr;
    },
    parseLanguage: function (language, index) {
        for (let i in language) {
            if (language.hasOwnProperty(i)) {
                if (Array.isArray(language[i])) {
                    language[i] = language[i][index];
                } else {
                    this.parseLanguage(language[i], index);
                }
            }
        }

        return language;
    },
    getLanguage: function () {
        var cache = localStorage.getItem(config.PREFIX + 'LANGUAGE');
        return isNaN(parseInt(cache)) ? 0 : cache;
    },
    setLanguage: function (index) {
        localStorage.setItem(config.PREFIX + 'LANGUAGE', index);
    }
};
export default {
    LAN,
    LF,
    language: LF.exportLanguage()
};
